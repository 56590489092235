import React from "react"
import { Card as CardComponent, css } from 'theme-ui'
import Seo from '@widgets/Seo'
import { graphql } from "gatsby"
import Divider from '@components/Divider'
import TyrePatternCompact from './TyrePatternCompact'
import PageTitle from '@components/PageTitle'
import { Layout, Stack, Main, Sidebar } from '@layout'
import SizeList from "@src/SizeList"
import translate from '@src/translate'
import speed from '@src/speed'
import load from '@src/load'

const ExternalLink = props => {
  if (props.href.includes('alupriser.dk') || props.href[0] === '/' || props.href[0] === '#') {
    return <a href={props.href}>{props.children}</a>
  }
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

const components = {
  a: ExternalLink,
  tyresizes: SizeList
}

export default function PatternTemplate({
                                       data: page,
                                       brand,
                                       ...props
                                     }) {
  let pattern = page.allTyrePattern.edges[0].node;
  let context = props.pageContext;
  const image = pattern.image && pattern.image.childImageSharp;

  return (
    <Layout {...props}>
      <Seo {...props} description={pattern.short} title={context.name} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header={context.name}  />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            {context.brand} {context.pattern} er et {translate(pattern.season)} som
            kan holde til en maks hastighed på {speed(context.speedIndex.speedSymbol)}km/t og
            en belastning på {load(context.loadIndex)}lbs.
            <br/>
            <br/>
            {pattern.short}
          </CardComponent>
        </Main>
        <Sidebar>
          <TyrePatternCompact brand={context.brand} pattern={context.pattern} image={image} omitTitle/>
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export const query = graphql`
  query($brand: String!, $pattern: String!) {
      allTyrePattern(filter: { pattern: { eq: $pattern }, brand: { eq: $brand } }) {
        edges {
          node {
            short
            season
            image {
              childImageSharp {
                __typename
                ... on ImageSharp {
                  ImageSharp_small: gatsbyImageData(
                    width: 48
                    height: 48
                    layout: FIXED
                    transformOptions: {cropFocus: NORTH}
                    placeholder: TRACED_SVG
                    quality: 75
                  )
                  ImageSharp_regular: gatsbyImageData(
                    width: 150
                    height: 150
                    layout: FIXED
                    transformOptions: {cropFocus: NORTH}
                    placeholder: TRACED_SVG
                    quality: 80
                  )
                }
              }
            }
          }
        }
      }
    }
`
