export default function translate(text)
{
  const t = {
    'Summer': "Sommerdæk",
    'SUMMER': "sommerdæk",
    'All_Year': "Helårsdæk",
    'ALL_YEAR': "helårsdæk",
    'Winter': "Vinterdæk",
    'WINTER': "Vinterdæk",
    'facetCarType': 'Biltype',
    'facetSeason': 'Sæson',
    'VAN': 'Varevogn',
    'CAR': 'Personbil'
  }

  if(t[text] === undefined){
    return text;
  }
  return t[text];
}