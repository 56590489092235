export default function load(index)
{
  const load = {
    51: 195,
    52: 200,
    53: 206,
    54: 212,
    55: 218,
    56: 224,
    57: 230,
    58: 236,
    59: 243,
    60: 250,
    61: 257,
    62: 265,
    63: 272,
    64: 280,
    65: 290,
    66: 300,
    67: 307,
    68: 315,
    69: 325,
    70: 335,
    71: 345,
    72: 355,
    73: 365,
    74: 375,
    75: 387,
    76: 400,
    77: 412,
    78: 425,
    79: 437,
    80: 450,
    81: 462,
    82: 475,
    83: 487,
    84: 500,
    85: 515,
    86: 530,
    87: 545,
    88: 560,
    89: 580,
    90: 600,
    91: 615,
    92: 630,
    93: 650,
    94: 670,
    95: 690,
    96: 710,
    97: 730,
    98: 750,
    99: 775,
    100: 800,
    101: 825,
    102: 850,
    103: 875,
    104: 900,
    105: 925,
    106: 950,
    107: 975,
    108: 1000,
    109: 1030,
    110: 1060,
    111: 1090,
    112: 1120,
    113: 1150,
    114: 1180,
    115: 1215,
    116: 1250,
    117: 1285,
    118: 1320,
    119: 1360,
    120: 1400,
    121: 1450,
    122: 1500,
    123: 1550,
    124: 1600,
    125: 1650,
    126: 1700,
    127: 1750,
    128: 1800,
    129: 1850,
    130: 1900,
    131: 1950,
    132: 2000,
    133: 2060,
    134: 2120,
    135: 2180,
    136: 2240,
    137: 2300,
    138: 2360,
    139: 2430,
    140: 2500,
    141: 2575,
    142: 2650,
    143: 2725,
    144: 2800,
    145: 2900,
    146: 3000,
    147: 3075,
    148: 3150,
    149: 3250,
    150: 3350,
    151: 3450,
    152: 3550,
    153: 3650,
    154: 3750,
    155: 3875,
    156: 4000,
    157: 4125,
    158: 4250,
    159: 4375,
    160: 4500,
    161: 4625,
    162: 4750,
    163: 4875,
    164: 5000,
    165: 5150,
    166: 5300,
    167: 5450,
    168: 5600,
    169: 5800,
    170: 6000,
    171: 6150,
    172: 6300,
    173: 6500,
    174: 6700,
    175: 6900,
    176: 7100,
    177: 7300,
    178: 7500,
    179: 7750,
    180: 8000,
    181: 8250,
    182: 8500,
    183: 8750,
    184: 9000,
    185: 9250,
    186: 9500,
    187: 9750,
    188: 10000,
    189: 10300,
    190: 10600,
    191: 10900,
    192: 11200,
    193: 11500,
    194: 11800,
    195: 12150,
    196: 12500,
    197: 12850,
    198: 13200,
    199: 13600,
    200: 14000,
    201: 14500,
    202: 15000,
    203: 15500,
    204: 16000,
    205: 16500,
    206: 17000,
    207: 17500,
    208: 18000,
    209: 18500,
    210: 19000,
    211: 19500,
    212: 20000,
    213: 20600,
    214: 21200,
    215: 21800,
    216: 22400,
    217: 23000,
    218: 23600,
    219: 24300,
    220: 25000,
    221: 25750,
    222: 26500,
    223: 27250,
    224: 28000,
    225: 29000,
    226: 30000,
    227: 30750,
    228: 31500,
    229: 32500,
    230: 33500,
    231: 34500,
    232: 35500,
    233: 36500,
    234: 37500,
    235: 38750,
    236: 40000,
    237: 41250,
    238: 42500,
    239: 43750,
    240: 45000,
    241: 46250,
    242: 47500,
    243: 48750,
    244: 50000,
    245: 51500,
    246: 53000,
    247: 54500,
    248: 56000,
    249: 58000,
    250: 60000,
  };

  return load[index.singleAxelLoadIndex];
}